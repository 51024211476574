
import { Options } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";

import TheoryBlock from "@/components/Pages/Home/Theory/TheoryBlock.vue";
import PlaygroundBlock from "@/components/Pages/Theory/PlaygroundBlock.vue";
import PreliminaryBlock from "@/components/Pages/PreliminaryExam/PreliminaryBlock.vue";
import StudentEducationsStoreMixin from "@/mixins/StudentEducations/StudentEducationsStoreMixin";
import { mixins } from "vue-class-component";
import PreExamList from "@/components/Pages/PreliminaryExam/PreExamList.vue";
import ForwardHeader from "@/components/Headers/ForwardHeader.vue";

@Options({
  components: {
    ForwardHeader,
    PreExamList,
    PreliminaryBlock,
    PlaygroundBlock,
    TheoryBlock,
    DefaultLayout,
  },
})
export default class PreliminaryExam extends mixins(StudentEducationsStoreMixin) {}
